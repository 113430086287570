import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

import section_img_3d from "../../images/3D_section_img.png"
import highlight_img from "../../images/highlight_image.png"

import gsap from "gsap";
import { title } from 'framer-motion/client';
import { Navigate } from 'react-router-dom';
import Footer from '../sections/Footer';
import Navbar from '../sections/Navbar';

const LandingPage = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesHovered, setIsServicesHovered] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {

    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsServicesHovered(false);
      }
    }

    if (isServicesHovered) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isServicesHovered]);

  // ---------------------------- Hover Data ---------------------------- 

  const [hoveredService, SetHoveredService] = useState("Application Security");

  const ServicesData = [
    {
      title: "Application Security",
      subtitle: "Web Application Pentesting, Mobile Application Pentesting, API Pentesting, Secure code review",
      options: [
        { name: "Web Application Penetration Testing", link: "/application-security/web-app-penetration-testing" },
        { name: "Mobile Application Pentesting", link: "/application-security/mobile-app-penetration-testing" },
        { name: "Web Services & API Assessment", link: "/application-security/api-assessment" },
        { name: "Threat Modeling", link: "/application-security/threat-modeling" },
        { name: "Secure Code Review", link: "/application-security/secure-code-review" },
        { name: "Application Architecture Review", link: "/application-security/architecture-review" },
      ],
    },
    {
      title: "AI / ML Security",
      subtitle: "AI/LLM Applications Pen Testing",
      options: [
        { name: "AI Model Penetration Testing", link: "/ai-ml-security/ai-model-pen-testing" },
        { name: "LLM Security Assessment", link: "/ai-ml-security/llm-security-assessment" },
        { name: "Adversarial Attacks Analysis", link: "/ai-ml-security/adversarial-attacks" },
        { name: "Model Explainability Testing", link: "/ai-ml-security/model-explainability" },
      ],
    },
    {
      title: "Cloud Security",
      subtitle: "Auditing and Pentesting of AWS, Azure, and GCP",
      options: [
        { name: "Cloud Auditing", link: "/cloud-security/cloud-auditing" },
        { name: "Cloud Pentesting", link: "/cloud-security/cloud-penetration-testing" },
      ],
    },
    {
      title: "Web3 Security",
      subtitle: "Auditing",
      options: [
        { name: "Smart Contract Auditing", link: "/web3-security/smart-contract-auditing" },
        { name: "Blockchain Security Assessment", link: "/web3-security/blockchain-security" },
        { name: "DeFi Application Testing", link: "/web3-security/defi-application-testing" },
        { name: "Wallet Security Analysis", link: "/web3-security/wallet-security" },
      ],
    },
    {
      title: "Network Security",
      subtitle: "Internal and External Network Pen Testing Including Reteaming",
      options: [
        { name: "Network Vulnerability Assessment and Penetration Testing", link: "/network-security/vulnerability-assessment" },
        { name: "Device Security", link: "/network-security/device-security" },
        { name: "VoIP Vulnerability Assessment & Penetration Testing", link: "/network-security/voip-testing" },
        { name: "Wireless Penetration Testing", link: "/network-security/wireless-penetration-testing" },
      ],
    },
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    gsap.fromTo(
      ".hero-title",
      { opacity: 0, x: -100 },  // Start with opacity 0 and off-screen to the left
      {
        opacity: 1,
        x: 0,  // Move to its normal position
        duration: 1.5,  // Slower fade-in effect
        ease: "power2.out",  // Smooth easing
      }
    );

    // Optional: Add a subtle hover effect
    gsap.to(".hero-title", {
      opacity: 0.3,  // Slight opacity change on hover for a classy touch
      duration: 0.3,
      paused: true,
      yoyo: true,
      ease: "power1.inOut",
      onHover: true,
    });
  }, []);


  useEffect(() => {
    // Animate the hero title
    gsap.fromTo(
      ".hero-title",
      { opacity: 0, x: -100 },  // Start with opacity 0 and off-screen to the left
      {
        opacity: 1,
        x: 0,  // Move to its normal position
        duration: 1.5,  // Slower fade-in effect
        ease: "power2.out",  // Smooth easing
      }
    );

    // Animate the blurred background div
    gsap.fromTo(
      ".hero-bg",
      { opacity: 1, y: 300 },  // Start with opacity 0 and below the screen
      {
        opacity: 1,
        y: -100,  // Move to its normal position
        duration: 1.5,  // Slower movement to match the hero-title animation
        ease: "power2.out",  // Smooth easing
      }
    );

    const tl = gsap.timeline();

    // Step 1: Element comes from below
    tl.fromTo(
      ".bg_shield",
      { opacity: 0, y: 450 },
      {
        opacity: 1,
        y: 0,
        duration: 1.5,
        ease: "power2.out",
      }
    );

    // Step 2: Continuous up-and-down animation
    tl.to(".bg_shield", {
      y: 50, // Moves 50px down
      duration: 2,
      ease: "power1.inOut",
      repeat: -1, // Infinite repeat
      yoyo: true, // Reverses the animation
    });



    // Optional: Add a subtle hover effect to hero title
    gsap.to(".hero-title", {
      opacity: 0.3,  // Slight opacity change on hover for a classy touch
      duration: 0.3,
      paused: true,
      yoyo: true,
      ease: "power1.inOut",
      onHover: true,
    });
  }, []);


  return (

    <div className="bg-dark text-white font-sans overflow-hidden ">

      <Navbar />

      {/* Hero Section */}
      <section className="hero-section h-[100dvh] flex items-center justify-center bg-[#0E0E0E] bg-cover bg-center">
        <div className="relative h-full w-full overflow-hidden flex items-center justify-center">
          {/* Light beams effect */}
          <div className="light_beams absolute top-0 left-1/2 transform -translate-x-1/2 w-full h-full pointer-events-none z-10">
            <div className="beam_1"></div>
            <div className="beam_2"></div>
            <div className="beam_3"></div>
          </div>

          {/* Hero content */}
          <div className="p-4 sm:p-6 md:mx-10 lg:mx-20 xl:mx-32 text-left flex flex-col gap-4 md:gap-6 z-20">
            <span className="hero-title px-3 py-1 sm:px-4 sm:py-2 bg-[#D1F60A] text-black text-center font-semibold rounded-full w-[254px] whitespace-nowrap text-sm sm:text-base md:text-lg">
              Solution to your Security
            </span>
            <h1 className="hero-title text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 leading-tight max-w-[90%] sm:max-w-[56rem] text-white">
              Uncover Vulnerabilities <br /> Before They Become Threats!
            </h1>
          </div>

          {/* Blurred background element */}
          <div className="hero-bg bg-[#D1F60A] absolute top-[-350px] sm:bottom-[-200px] h-[300px] w-[300px] sm:h-[400px] sm:w-[400px] md:h-[520px] md:w-[520px] blur-[120px] sm:blur-[150px] md:blur-[170px]"></div>

          {/* Shield background element */}
          <svg className="bg_shield absolute top-[55vh] lg:top-[55vh] h-[600px] w-[1800px] sm:h-[800px] sm:w-[800px] md:h-[1000px] md:w-[1800px]" viewBox="0 0 1358 1358" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_197_625)">
              <circle cx="679" cy="679" r="479" fill="#D0F50A" />
              <circle cx="679" cy="679" r="479" fill="url(#paint0_linear_197_625)" fill-opacity="0.3" />
              <circle cx="679" cy="679" r="479" fill="url(#paint1_linear_197_625)" fill-opacity="0.3" />
              <circle cx="679" cy="679" r="483.181" stroke="url(#paint2_linear_197_625)" stroke-width="8.3616" />
            </g>
            <g filter="url(#filter1_d_197_625)">
              <circle cx="679" cy="679" r="381.05" fill="url(#paint3_linear_197_625)" />
            </g>
            <g filter="url(#filter2_d_197_625)">
              <circle cx="679" cy="679" r="283.1" fill="url(#paint4_linear_197_625)" fill-opacity="0.7" shape-rendering="crispEdges" />
              <circle cx="679" cy="679" r="283.1" fill="url(#paint5_linear_197_625)" fill-opacity="0.7" shape-rendering="crispEdges" />
              <circle cx="679" cy="679" r="283.1" fill="url(#paint6_linear_197_625)" fill-opacity="0.7" shape-rendering="crispEdges" />
              <circle cx="679" cy="679" r="283.1" fill="url(#paint7_linear_197_625)" fill-opacity="0.7" shape-rendering="crispEdges" />
            </g>
            <g filter="url(#filter3_f_197_625)">
              <circle cx="679" cy="679" r="45.6052" stroke="#DEDEDE" stroke-width="2.22464" />
              <circle cx="679" cy="679" r="61.1777" stroke="#DEDEDE" stroke-width="2.22464" />
              <circle cx="679" cy="679" r="78.2333" stroke="#DEDEDE" stroke-width="2.22464" />
              <circle cx="679" cy="679" r="96.772" stroke="#DEDEDE" stroke-width="2.22464" />
              <circle cx="679" cy="679" r="133.108" stroke="#DEDEDE" stroke-width="2.22464" />
              <circle cx="679" cy="679" r="187.982" stroke="#DEDEDE" stroke-width="2.22464" />
              <circle cx="679" cy="679" r="241.374" stroke="#DEDEDE" stroke-width="2.22464" />
              <circle cx="679" cy="679" r="30.0327" stroke="#DEDEDE" stroke-width="2.22464" />
            </g>
            <g filter="url(#filter4_f_197_625)">
              <ellipse cx="515.171" cy="232.783" rx="240.042" ry="30.7312" transform="rotate(-19.8535 515.171 232.783)" fill="url(#paint8_linear_197_625)" />
            </g>
            <g clip-path="url(#clip0_197_625)">
              <g filter="url(#filter5_f_197_625)">
                <ellipse cx="508.359" cy="228.793" rx="109.739" ry="8.36506" transform="rotate(-40.9573 508.359 228.793)" fill="url(#paint9_linear_197_625)" />
              </g>
              <g filter="url(#filter6_f_197_625)">
                <ellipse cx="506.352" cy="224.566" rx="109.739" ry="8.36506" transform="rotate(38.8962 506.352 224.566)" fill="url(#paint10_linear_197_625)" />
              </g>
            </g>
            <g filter="url(#filter7_f_197_625)">
              <ellipse cx="638.921" cy="573.264" rx="195.676" ry="14.9157" transform="rotate(-40.9573 638.921 573.264)" fill="url(#paint11_linear_197_625)" />
            </g>
            <g filter="url(#filter8_f_197_625)">
              <ellipse cx="635.342" cy="564.941" rx="195.676" ry="14.9157" transform="rotate(38.8962 635.342 564.941)" fill="url(#paint12_linear_197_625)" />
            </g>
            <g clip-path="url(#clip1_197_625)">
              <g filter="url(#filter9_f_197_625)">
                <ellipse cx="887.647" cy="1116.04" rx="72.6311" ry="5.53643" transform="rotate(-40.9573 887.647 1116.04)" fill="url(#paint13_linear_197_625)" />
              </g>
              <g filter="url(#filter10_f_197_625)">
                <ellipse cx="886.319" cy="1113.24" rx="72.6311" ry="5.53643" transform="rotate(38.8962 886.319 1113.24)" fill="url(#paint14_linear_197_625)" />
              </g>
            </g>
            <g filter="url(#filter11_f_197_625)">
              <ellipse cx="972.592" cy="1059.68" rx="144.067" ry="23.8066" transform="rotate(-38.5111 972.592 1059.68)" fill="url(#paint15_linear_197_625)" />
            </g>
            <g filter="url(#filter12_dii_197_625)">
              <path d="M662.427 109.71L650.485 97.9532C649.244 96.7439 646.82 95.6638 645.09 95.5492L628.364 94.5304C625.137 94.3418 621.455 96.5748 620.143 99.5198L610.735 120.63C609.795 122.741 609.933 126.146 611.07 128.151L620.631 145.251C622.311 148.28 626.455 150.126 629.831 149.35L648.939 145.026C651.202 144.504 653.814 142.357 654.755 140.246L664.162 119.136C665.5 116.202 664.699 111.972 662.427 109.71Z" fill="#CCF10A" />
            </g>
            <g filter="url(#filter13_dii_197_625)">
              <path d="M358.494 233.941C358.12 232.461 359.403 231.091 360.904 231.366L368.837 232.823C369.354 232.918 369.887 232.815 370.332 232.535L377.159 228.241C378.451 227.428 380.151 228.225 380.352 229.738L381.419 237.733C381.488 238.253 381.75 238.729 382.154 239.065L388.348 244.231C389.52 245.209 389.287 247.071 387.911 247.731L380.637 251.215C380.163 251.442 379.792 251.839 379.597 252.326L376.598 259.814C376.03 261.231 374.187 261.585 373.134 260.48L367.572 254.638C367.21 254.258 366.719 254.027 366.195 253.992L358.147 253.454C356.624 253.352 355.717 251.708 356.443 250.366L360.28 243.271C360.53 242.809 360.597 242.27 360.469 241.761L358.494 233.941Z" fill="#CCF10B" />
            </g>
            <defs>
              <filter id="filter0_d_197_625" x="0.515991" y="0.516235" width="1356.97" height="1356.97" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="95.5611" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.819608 0 0 0 0 0.956863 0 0 0 0 0.0941176 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_197_625" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_197_625" result="shape" />
              </filter>
              <filter id="filter1_d_197_625" x="238.225" y="238.224" width="881.551" height="881.551" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="29.8628" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_197_625" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_197_625" result="shape" />
              </filter>
              <filter id="filter2_d_197_625" x="336.175" y="336.175" width="685.651" height="685.651" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="29.8628" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.823529 0 0 0 0 0.960784 0 0 0 0 0.0823529 0 0 0 0.12 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_197_625" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_197_625" result="shape" />
              </filter>
              <filter id="filter3_f_197_625" x="407.678" y="407.678" width="542.644" height="542.644" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="14.4178" result="effect1_foregroundBlur_197_625" />
              </filter>
              <filter id="filter4_f_197_625" x="241.37" y="98.4845" width="547.603" height="268.598" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="23.8903" result="effect1_foregroundBlur_197_625" />
              </filter>
              <filter id="filter5_f_197_625" x="414.378" y="145.657" width="187.962" height="166.274" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="5.46091" result="effect1_foregroundBlur_197_625" />
              </filter>
              <filter id="filter6_f_197_625" x="409.858" y="144.426" width="192.989" height="160.28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="5.46091" result="effect1_foregroundBlur_197_625" />
              </filter>
              <filter id="filter7_f_197_625" x="426.769" y="380.449" width="424.303" height="385.63" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="32.0244" result="effect1_foregroundBlur_197_625" />
              </filter>
              <filter id="filter8_f_197_625" x="418.709" y="377.469" width="433.267" height="374.943" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="32.0244" result="effect1_foregroundBlur_197_625" />
              </filter>
              <filter id="filter9_f_197_625" x="825.445" y="1061.02" width="124.403" height="110.048" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="3.61431" result="effect1_foregroundBlur_197_625" />
              </filter>
              <filter id="filter10_f_197_625" x="822.454" y="1060.2" width="127.73" height="106.082" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="3.61431" result="effect1_foregroundBlur_197_625" />
              </filter>
              <filter id="filter11_f_197_625" x="811.097" y="920.265" width="322.989" height="278.837" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="23.8903" result="effect1_foregroundBlur_197_625" />
              </filter>
              <filter id="filter12_dii_197_625" x="581.445" y="70.629" width="112.035" height="112.345" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4.77805" />
                <feGaussianBlur stdDeviation="14.3342" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_197_625" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_197_625" result="shape" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4.77805" />
                <feGaussianBlur stdDeviation="2.38903" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.75 0" />
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_197_625" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="-2.38903" />
                <feGaussianBlur stdDeviation="2.38903" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.75 0" />
                <feBlend mode="normal" in2="effect2_innerShadow_197_625" result="effect3_innerShadow_197_625" />
              </filter>
              <filter id="filter13_dii_197_625" x="305.892" y="186.003" width="133.507" height="133.807" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="8.38279" />
                <feGaussianBlur stdDeviation="25.1484" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_197_625" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_197_625" result="shape" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="-4.1914" />
                <feGaussianBlur stdDeviation="4.1914" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.75 0" />
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_197_625" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="8.38279" />
                <feGaussianBlur stdDeviation="4.1914" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.75 0" />
                <feBlend mode="normal" in2="effect2_innerShadow_197_625" result="effect3_innerShadow_197_625" />
              </filter>
              <linearGradient id="paint0_linear_197_625" x1="616.288" y1="308.701" x2="588.814" y2="216.723" gradientUnits="userSpaceOnUse">
                <stop stop-color="white" stop-opacity="0" />
                <stop offset="1" stop-color="white" />
              </linearGradient>
              <linearGradient id="paint1_linear_197_625" x1="662.874" y1="430.541" x2="588.814" y2="216.723" gradientUnits="userSpaceOnUse">
                <stop stop-color="white" stop-opacity="0" />
                <stop offset="1" stop-color="white" />
              </linearGradient>
              <linearGradient id="paint2_linear_197_625" x1="149.83" y1="384.552" x2="968.67" y2="63.2282" gradientUnits="userSpaceOnUse">
                <stop stop-color="#D0F50A" />
                <stop offset="0.515" stop-color="white" />
                <stop offset="1" stop-color="#D0F50A" />
              </linearGradient>
              <linearGradient id="paint3_linear_197_625" x1="311.687" y1="432.333" x2="1060.05" y2="386.344" gradientUnits="userSpaceOnUse">
                <stop />
                <stop offset="0.43" stop-color="#34381E" />
                <stop offset="1" />
              </linearGradient>
              <linearGradient id="paint4_linear_197_625" x1="342.744" y1="679" x2="1061.24" y2="679" gradientUnits="userSpaceOnUse">
                <stop />
                <stop offset="0.46" stop-color="#656565" />
                <stop offset="1" />
              </linearGradient>
              <linearGradient id="paint5_linear_197_625" x1="357.079" y1="870.72" x2="1039.74" y2="406.054" gradientUnits="userSpaceOnUse">
                <stop />
                <stop offset="0.46" stop-color="#656565" />
                <stop offset="1" />
              </linearGradient>
              <linearGradient id="paint6_linear_197_625" x1="459.807" y1="499.823" x2="902.971" y2="845.635" gradientUnits="userSpaceOnUse">
                <stop />
                <stop offset="0.46" stop-color="#656565" />
                <stop offset="1" />
              </linearGradient>
              <linearGradient id="paint7_linear_197_625" x1="483.1" y1="824.134" x2="846.829" y2="440.097" gradientUnits="userSpaceOnUse">
                <stop stop-opacity="0" />
                <stop offset="0.46" stop-color="#656565" />
                <stop offset="1" stop-opacity="0" />
              </linearGradient>
              <linearGradient id="paint8_linear_197_625" x1="275.129" y1="232.783" x2="755.214" y2="232.783" gradientUnits="userSpaceOnUse">
                <stop stop-color="white" stop-opacity="0" />
                <stop offset="0.53" stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </linearGradient>
              <linearGradient id="paint9_linear_197_625" x1="398.62" y1="228.793" x2="618.099" y2="228.793" gradientUnits="userSpaceOnUse">
                <stop stop-color="white" stop-opacity="0" />
                <stop offset="0.53" stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </linearGradient>
              <linearGradient id="paint10_linear_197_625" x1="396.613" y1="224.566" x2="616.092" y2="224.566" gradientUnits="userSpaceOnUse">
                <stop stop-color="white" stop-opacity="0" />
                <stop offset="0.53" stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </linearGradient>
              <linearGradient id="paint11_linear_197_625" x1="443.245" y1="573.264" x2="834.597" y2="573.264" gradientUnits="userSpaceOnUse">
                <stop stop-color="white" stop-opacity="0" />
                <stop offset="0.53" stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </linearGradient>
              <linearGradient id="paint12_linear_197_625" x1="439.666" y1="564.941" x2="831.018" y2="564.941" gradientUnits="userSpaceOnUse">
                <stop stop-color="white" stop-opacity="0" />
                <stop offset="0.53" stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </linearGradient>
              <linearGradient id="paint13_linear_197_625" x1="815.015" y1="1116.04" x2="960.278" y2="1116.04" gradientUnits="userSpaceOnUse">
                <stop stop-color="white" stop-opacity="0" />
                <stop offset="0.53" stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </linearGradient>
              <linearGradient id="paint14_linear_197_625" x1="813.688" y1="1113.24" x2="958.95" y2="1113.24" gradientUnits="userSpaceOnUse">
                <stop stop-color="white" stop-opacity="0" />
                <stop offset="0.53" stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </linearGradient>
              <linearGradient id="paint15_linear_197_625" x1="828.524" y1="1059.68" x2="1116.66" y2="1059.68" gradientUnits="userSpaceOnUse">
                <stop stop-color="white" stop-opacity="0" />
                <stop offset="0.53" stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </linearGradient>
              <clipPath id="clip0_197_625">
                <rect width="212.116" height="198.439" fill="white" transform="translate(372.01 170.062) rotate(-21.1038)" />
              </clipPath>
              <clipPath id="clip1_197_625">
                <rect width="140.389" height="131.337" fill="white" transform="translate(797.404 1077.17) rotate(-21.1038)" />
              </clipPath>
            </defs>
          </svg>

        </div>
      </section>


      {/* Bento Grid Section */}
      <section className="py-36 bg-[#0E0E0E]">
        <div className="container mx-auto px-6">
          <h2 className="text-center text-3xl font-semibold mb-8 text-white">Why AppSecNinja?</h2>

          <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4">

            {/* Hero Block */}
            <div className="relative overflow-hidden bg-[#1D1D1D] rounded-lg p-6 flex flex-col justify-center min-h-[auto] sm:h-64 lg:row-span-2 lg:h-full border border-transparent hover:border-[#D1F60A] transition-all duration-300 ease-in-out transform hover:scale-105 shadow-md hover:shadow-lg ">
              <h3 className="text-xl font-bold text-center text-white">Protect Your Digital Assets</h3>
              <p className="text-center text-white mt-2">Our comprehensive platform safeguards your business against emerging threats.</p>
              <button className="mt-4 px-4 py-2 bg-[#D1F60A] text-black rounded-lg">Get Started</button>
            </div>

            {/* Why Choose Us */}
            <div className="relative overflow-hidden bg-[#1D1D1D] rounded-lg p-6 flex flex-col justify-center min-h-[auto] sm:h-64 border border-transparent hover:border-[#D1F60A] transition-all duration-300 ease-in-out transform hover:scale-105 shadow-md hover:shadow-lg ">
              <h3 className="text-xl font-bold text-left text-white">Why Choose Us</h3>
              <ul className="mt-2 text-white text-left">
                <li>Advanced Threat Detection</li>
                <li>Real-Time Monitoring</li>
                <li>ISO & GDPR Compliance</li>
                <li>User-Friendly Dashboard</li>
              </ul>
            </div>

            {/* Key Features */}
            <div className="relative overflow-hidden bg-[#1D1D1D] rounded-lg p-6 flex flex-col justify-center min-h-[auto] sm:h-64 border border-transparent hover:border-[#D1F60A] transition-all duration-300 ease-in-out transform hover:scale-105 shadow-md hover:shadow-lg ">
              <h3 className="text-xl font-bold text-left text-white">Powerful Features</h3>
              <ul className="mt-2 text-white text-left">
                <li>Firewall & VPN Protection</li>
                <li>Behavioral Analytics</li>
                <li>Automated Response</li>
                <li>AI-Powered Threat Analysis</li>
              </ul>
            </div>

            {/* Getting Started */}
            <div className="relative overflow-hidden bg-[#1D1D1D] rounded-lg p-6 flex flex-col justify-center min-h-[auto] sm:h-64 lg:col-span-2 sm:col-span-3 border border-transparent hover:border-[#D1F60A] transition-all duration-300 ease-in-out transform hover:scale-105 shadow-md hover:shadow-lg ">
              <h3 className="text-xl font-bold text-left text-white">Getting Started</h3>
              <ol className="mt-2 text-white text-left list-decimal list-inside">
                <li>Sign Up for Free - Create your account with just a few clicks.</li>
                <li>Complete Your Profile - Tailor the platform to your needs.</li>
                <li>Start Your First Assessment - Identify vulnerabilities and get recommendations.</li>
                <li>Engage with Our Support Team - Connect with our experts for guidance.</li>
              </ol>
            </div>

          </div>
        </div>
      </section>


      {/* Highlight Section */}
      <section className="w-full overflow-hidden relative">
        <div className="w-full aspect-[16/9] sm:aspect-[3/1] md:aspect-[4/3] lg:aspect-[3/1]">
          <img
            src={highlight_img}
            className="w-full h-full object-cover"
            alt="Highlight_Image"
          />
        </div>
      </section>


      {/* Testimonial Section */}
      <section className="w-full py-36 bg-[#0E0E0E]">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Section Title */}
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-left text-white mb-8">
            Testimo<span className="text-[#D1F60A]">nials</span>
          </h2>

          {/* Testimonial 1 */}
          <div className="mb-12 flex flex-col">
            <blockquote className="text-[#707070] text-lg sm:text-2xl lg:text-3xl font-bold mb-4 leading-relaxed">
              "Appsecninja stands out for their technical skill and dedication to clear, comprehensive reporting. Having worked with them on multiple web app security assessments, I can vouch for their professionalism, reliability, and impactful contributions—making them an ideal choice for web application testing."
            </blockquote>
            <p className="text-white mt-6 font-semibold text-lg sm:text-xl">Omair Mohammed</p>
            <sub className="text-[#D1F60A] text-sm italic">
              (Director Offensive Security at KrashConsulting)
            </sub>
          </div>

          {/* Testimonial 2 */}
          <div className="mb-12 flex flex-col">
            <blockquote className="text-[#707070] text-lg sm:text-2xl lg:text-3xl font-bold mb-4 leading-relaxed">
              "I hired Appsecninja and their work was top-notch. Their pen test went deeper and found more vulnerabilities than anyone else I had thrown at the same problem. They have a team of top researchers who found vulnerabilities in Fortune 100 Companies such as Apple."
            </blockquote>
            <p className="text-white mt-6 font-semibold text-lg sm:text-xl">Allan Alford</p>
            <sub className="text-[#D1F60A] text-sm italic">
              (CEO Allan & Alford Consulting)
            </sub>
          </div>
        </div>
      </section>



      {/* CyberSecurity Description Section */}
      <section className="py-16 lg:py-24 bg-[#0E0E0E]">
        <div className="container mx-auto flex flex-col lg:flex-row items-center lg:justify-center lg:px-6 px-4 text-left">
          {/* Left Column - Text Content */}
          <div className="lg:w-1/2 lg:pr-8 mb-8 lg:mb-0 text-white">
            <h2 className="text-3xl lg:text-4xl font-semibold mb-4 leading-tight lg:leading-snug">
              Stay Ahead of Cyber Threats with Expert Penetration Testing
            </h2>
            <h3 className="text-xl lg:text-2xl font-medium mb-8 text-gray-300">
              Discover vulnerabilities before attackers do
            </h3>

            {/* Numbered Paragraphs */}
            <div className="mb-6 flex items-start space-x-4">
              <div className="flex flex-shrink-0 items-center justify-center w-[40px] h-[40px] lg:w-[44px] lg:h-[44px] rounded-full bg-[#323232] text-white font-bold">
                1
              </div>
              <p className="text-base lg:text-lg leading-relaxed text-[#8b8b8b]">
                Our team of experts uses advanced tools and methodologies to simulate
                real-world attacks, ensuring your systems are secure.
              </p>
            </div>

            <div className="mb-6 flex items-start space-x-4">
              <div className="flex flex-shrink-0 items-center justify-center w-[40px] h-[40px] lg:w-[44px] lg:h-[44px] rounded-full bg-[#323232] text-white font-bold">
                2
              </div>
              <p className="text-base lg:text-lg leading-relaxed text-[#8b8b8b]">
                We identify vulnerabilities within your infrastructure, providing
                comprehensive insights and solutions to fortify defenses.
              </p>
            </div>

            <div className="mb-6 flex items-start space-x-4">
              <div className="flex flex-shrink-0 items-center justify-center w-[40px] h-[40px] lg:w-[44px] lg:h-[44px] rounded-full bg-[#323232] text-white font-bold">
                3
              </div>
              <p className="text-base lg:text-lg leading-relaxed text-[#8b8b8b]">
                Customized strategies for every client mean that your unique security
                needs are met with precision and care.
              </p>
            </div>
          </div>

          {/* Right Column - Image */}
          <div className="lg:w-1/3 w-full">
            <img
              src={section_img_3d}
              alt="Cybersecurity concept with a hacker"
              className="w-full h-auto rounded-lg shadow-lg object-cover"
            />
          </div>
        </div>
      </section>


      {/* FAQs */}
      <section className="py-12 bg-[#0E0E0E]">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-2xl sm:text-3xl font-semibold mb-6 text-white">FAQs</h2>

          <div className="space-y-4 max-w-3xl mx-auto">
            {/* FAQ 1 */}
            <details className="bg-[#1D1D1D] rounded-md p-4 transition-all duration-300 ease-in-out text-left shadow-md">
              <summary className="font-semibold text-white cursor-pointer hover:text-[#D1F60A] focus:outline-none transition duration-300 ease-in-out">
                What is a penetration test?
              </summary>
              <p className="mt-2 text-left text-gray-300 transition-opacity duration-300 ease-in-out">
                A penetration test is a simulated cyber attack to test system vulnerabilities.
              </p>
            </details>

            {/* FAQ 2 */}
            <details className="bg-[#1D1D1D] rounded-md p-4 transition-all duration-300 ease-in-out text-left shadow-md">
              <summary className="font-semibold text-white cursor-pointer hover:text-[#D1F60A] focus:outline-none transition duration-300 ease-in-out">
                How often should I perform a pentest?
              </summary>
              <p className="mt-2 text-left text-gray-300 transition-opacity duration-300 ease-in-out">
                We recommend at least once a year, depending on your risk profile.
              </p>
            </details>

            {/* FAQ 3 */}
            <details className="bg-[#1D1D1D] rounded-md p-4 transition-all duration-300 ease-in-out text-left shadow-md">
              <summary className="font-semibold text-white cursor-pointer hover:text-[#D1F60A] focus:outline-none transition duration-300 ease-in-out">
                What types of penetration tests are available?
              </summary>
              <p className="mt-2 text-left text-gray-300 transition-opacity duration-300 ease-in-out">
                There are several types of penetration tests, including network, application, physical, and social engineering tests. Each is designed to address specific areas of potential vulnerability.
              </p>
            </details>

            {/* FAQ 4 */}
            <details className="bg-[#1D1D1D] rounded-md p-4 transition-all duration-300 ease-in-out text-left shadow-md">
              <summary className="font-semibold text-white cursor-pointer hover:text-[#D1F60A] focus:outline-none transition duration-300 ease-in-out">
                What is the difference between black-box and white-box testing?
              </summary>
              <p className="mt-2 text-left text-gray-300 transition-opacity duration-300 ease-in-out">
                Black-box testing involves testing without any prior knowledge of the system, simulating an external attacker's perspective. White-box testing is performed with full knowledge of the system, focusing on internal vulnerabilities.
              </p>
            </details>

            {/* FAQ 5 */}
            <details className="bg-[#1D1D1D] rounded-md p-4 transition-all duration-300 ease-in-out text-left shadow-md">
              <summary className="font-semibold text-white cursor-pointer hover:text-[#D1F60A] focus:outline-none transition duration-300 ease-in-out">
                How long does a penetration test take?
              </summary>
              <p className="mt-2 text-left text-gray-300 transition-opacity duration-300 ease-in-out">
                The duration of a penetration test varies depending on the scope and complexity. A typical test can take from a few days to several weeks.
              </p>
            </details>
          </div>
        </div>
      </section>



      <Footer />

    </div>
  );
};

export default LandingPage;
