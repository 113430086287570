import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./components/LandingPage/LandingPage"
import WebAppPenTesting from "./components/pages/applicationSecurity/WebAppPenTesting"
import MobileAppPentesting from "./components/pages/applicationSecurity/MobileAppPentesting";
import ApiAssessment from "./components/pages/applicationSecurity/ApiAssessment";
import ThreatModeling from "./components/pages/applicationSecurity/ThreatModeling";
import SecureCodeReview from "./components/pages/applicationSecurity/SecureCodeReview";
import NotFound from "./components/pages/NotFound";
import ApplicationArchitectureReview from "./components/pages/applicationSecurity/ApplicationArchitectureReview";

const AllRoutes = () => {
  return (
    <>
      <Router>
        <Routes>

          {/* Route for the landing page */}
          <Route path="/" element={<LandingPage />} />


          {/* Services Routes */}

          {/* Application Security Routes */}
          <Route path="/application-security/web-app-penetration-testing" element={<WebAppPenTesting />} />
          <Route path="/application-security/mobile-app-penetration-testing" element={<MobileAppPentesting />} />
          <Route path="/application-security/api-assessment" element={<ApiAssessment />} />
          <Route path="/application-security/threat-modeling" element={<ThreatModeling />} />
          <Route path="/application-security/secure-code-review" element={<SecureCodeReview />} />
          <Route path="/application-security/architecture-review" element={< ApplicationArchitectureReview/>} />

          {/* AI / ML Security Routes
          <Route path="/ai-ml-security/ai-model-pen-testing" element={<AiMlSecurity />} />
          <Route path="/ai-ml-security/llm-security-assessment" element={<AiMlSecurity />} />
          <Route path="/ai-ml-security/adversarial-attacks" element={<AiMlSecurity />} />
          <Route path="/ai-ml-security/model-explainability" element={<AiMlSecurity />} />

          Cloud Security Routes
          <Route path="/cloud-security/cloud-auditing" element={<CloudSecurity />} />
          <Route path="/cloud-security/cloud-penetration-testing" element={<CloudSecurity />} />

          Web3 Security Routes
          <Route path="/web3-security/smart-contract-auditing" element={<Web3Security />} />
          <Route path="/web3-security/blockchain-security" element={<Web3Security />} />
          <Route path="/web3-security/defi-application-testing" element={<Web3Security />} />
          <Route path="/web3-security/wallet-security" element={<Web3Security />} />

          Network Security Routes
          <Route path="/network-security/vulnerability-assessment" element={<NetworkSecurity />} />
          <Route path="/network-security/device-security" element={<NetworkSecurity />} />
          <Route path="/network-security/voip-testing" element={<NetworkSecurity />} />
          <Route path="/network-security/wireless-penetration-testing" element={<NetworkSecurity />} /> */}

          <Route path="*" element={<NotFound/>} />
          

        </Routes>
      </Router>
    </>
  );
};

export default AllRoutes;