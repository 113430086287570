import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import gsap from "gsap";

const NotFound = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isServicesHovered, setIsServicesHovered] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsServicesHovered(false);
            }
        }

        if (isServicesHovered) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isServicesHovered]);

    // ---------------------------- Hover Data ---------------------------- 

    const [hoveredService, SetHoveredService] = useState("Application Security");

    const ServicesData = [
        {
            serviceTitle: "Application Security",
            serviceDescription: "We conduct web application penetration testing to uncover vulnerabilities.",
            svgIcon: "M9,13 L12,16 L21,6"
        },
        {
            serviceTitle: "Cloud Security",
            serviceDescription: "Cloud security testing for AWS, GCP, and Azure environments.",
            svgIcon: "M5,5 L15,15"
        },
        {
            serviceTitle: "Web3 & Blockchain",
            serviceDescription: "Security audits for decentralized applications and smart contracts.",
            svgIcon: "M1,3 L10,5 L15,1"
        }
    ];

    return (
        <div className="bg-dark text-white font-sans">

            {/* 404 Section */}
            <section className="h-screen flex items-center justify-start bg-[#0E0E0E] bg-cover bg-center relative overflow-hidden">
                <div className="relative overflow-y-scroll w-full h-full flex flex-col items-center justify-start text-center">

                    {/* Hero Title */}
                    <div className="p-4 sm:p-6 md:mx-10 lg:mx-20 xl:mx-32 text-left flex flex-col gap-4 md:gap-6 z-20">
                        <h1 className="hero-title text-center text-[20px] font-bold leading-tight max-w-[90%] sm:max-w-[56rem] text-white">
                            Page Not Found
                        </h1>
                        <h1 className="hero-title text-9xl font-bold mb-4 leading-tight max-w-[90%] sm:max-w-[56rem] text-opacity-10">
                            404
                        </h1>

                        <a href="/" className="text-[#D1F60A] text-center font-semibold text-lg sm:text-xl md:text-2xl hover:text-[#A0E00A] transition-all duration-300 ease-in-out">
                            Back to home
                        </a>


                    </div>

                    {/* Background Blur */}
                    <div className="hero-bg bg-[#D1F60A] absolute top-[-150px] sm:bottom-[-150px] h-[200px] sm:h-[300px] md:h-[420px] w-[200px] sm:w-[300px] md:w-[400px] blur-[120px] sm:blur-[150px] md:blur-[170px]"></div>

                    {/* Under Construction Message */}
                    <div className="py-12 bg-[#0E0E0E] w-full">
                        <div className="container mx-auto px-6">
                            <h2 className="text-center text-3xl font-semibold mb-8 text-white">Website Under Construction</h2>

                            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-4">

                                {/* Under Construction Block */}
                                <div className="relative overflow-hidden bg-[#1D1D1D] rounded-lg p-6 flex flex-col justify-center min-h-[auto] sm:h-64 border border-transparent hover:border-[#D1F60A] transition-all duration-300 ease-in-out transform hover:scale-105 shadow-md hover:shadow-lg ">
                                    <h3 className="text-xl font-bold text-center text-white">We Are Working On It</h3>
                                    <p className="text-center text-white mt-4">
                                        Our website is currently under construction. Some sections may be available, while others may not. We apologize for any inconvenience this may cause and appreciate your patience as we work to improve your experience.
                                    </p>
                                    <p className="text-center text-white mt-4">
                                        Thank you for visiting, and stay tuned for the upcoming updates!
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default NotFound;
