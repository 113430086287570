import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import gsap from "gsap";
import Navbar from '../../sections/Navbar';
import Footer from '../../sections/Footer';

const MobileAppPentesting = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isServicesHovered, setIsServicesHovered] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsServicesHovered(false);
            }
        }

        if (isServicesHovered) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isServicesHovered]);

    // ---------------------------- Hover Data ---------------------------- 

    const [hoveredService, SetHoveredService] = useState("Application Security");

    const ServicesData = [
        {
            serviceTitle: "Application Security",
            serviceDescription: "We conduct web application penetration testing to uncover vulnerabilities.",
            svgIcon: "M9,13 L12,16 L21,6"
        },
        {
            serviceTitle: "Cloud Security",
            serviceDescription: "Cloud security testing for AWS, GCP, and Azure environments.",
            svgIcon: "M5,5 L15,15"
        },
        {
            serviceTitle: "Web3 & Blockchain",
            serviceDescription: "Security audits for decentralized applications and smart contracts.",
            svgIcon: "M1,3 L10,5 L15,1"
        }
    ];

    // ---------------------------- Bento Grid Design ----------------------------

    const renderFuturisticSections = () => {
        const sections = [
            {
                title: "Advanced Threat Modeling",
                subtitle: "Proactively identify security threats using automated tools.",
                content: "Through AI-driven threat modeling, we identify potential vulnerabilities in the design phase.",
                bgColor: "#D1F60A",
                icon: "M10 10 L20 20 L30 10"
            },
            {
                title: "Automated Web App Pen Testing",
                subtitle: "Harness the power of AI to identify vulnerabilities automatically.",
                content: "Our advanced automation tools help uncover critical vulnerabilities with faster results.",
                bgColor: "#FF8C00",
                icon: "M5 15 L15 5 L25 15"
            },
            {
                title: "Cloud Security Integration",
                subtitle: "Comprehensive auditing for AWS, Azure, GCP.",
                content: "Security testing for cloud infrastructure ensures that your cloud environment is configured securely.",
                bgColor: "#1E90FF",
                icon: "M5 5 L15 15 L25 5"
            },
            {
                title: "Zero-Day Exploit Simulation",
                subtitle: "Simulating real-world attack scenarios with zero-day exploits.",
                content: "We simulate advanced attacks, including zero-day vulnerabilities, to test your system's response.",
                bgColor: "#8A2BE2",
                icon: "M5 15 L10 10 L20 15"
            },
            {
                title: "Web3 Security & Blockchain Auditing",
                subtitle: "Ensuring decentralized applications are secure.",
                content: "With the rise of decentralized technologies, our blockchain security audits safeguard Web3 projects.",
                bgColor: "#00CED1",
                icon: "M3 8 L7 10 L12 6"
            },
        ];

        return sections.map((section, index) => (
            <section key={index} className="py-16 md:py-32 bg-[#1E1E1E] text-left relative">
                <div className="max-w-[1200px] mx-auto px-4">
                    {/* Bento Grid SVG Background */}
                    <svg className="absolute top-0 left-0 w-full h-full opacity-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                        <rect x="10" y="10" width="30" height="30" fill={section.bgColor} />
                        <rect x="50" y="20" width="30" height="30" fill={section.bgColor} />
                        <path d={section.icon} stroke={section.bgColor} strokeWidth="2" fill="none" />
                    </svg>

                    <div className="relative z-10">
                        <h2 className="text-3xl md:text-4xl font-semibold text-white mb-6">{section.title}</h2>
                        <h3 className="text-lg text-[#8D8D8D] font-semibold mb-4">{section.subtitle}</h3>
                        <p className="text-lg text-[#B2B2B2]">{section.content}</p>
                    </div>
                </div>
            </section>
        ));
    };

    return (
        <div className="bg-dark text-white font-sans">
            <Navbar />

            {/* Hero Section */}
            <section className="hero-section h-screen flex items-center justify-center bg-[#0E0E0E] bg-cover bg-center">
                <div className="relative h-full w-full overflow-hidden flex items-center justify-center">
                    <div className="light_beams absolute top-0 left-1/2 transform -translate-x-1/2 w-full h-full pointer-events-none z-10">
                        <div className="beam_1"></div>
                        <div className="beam_2"></div>
                        <div className="beam_3"></div>
                    </div>
                    <div className="p-4 sm:p-6 md:mx-10 lg:mx-20 xl:mx-32 text-left flex flex-col gap-4 md:gap-6 z-20">
                        <h1 className="hero-title text-center text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 leading-tight max-w-[90%] sm:max-w-[56rem] text-white">
                            Mobile App Pentesting
                        </h1>
                    </div>
                    <div className="hero-bg bg-[#D1F60A] absolute top-[-350px] sm:bottom-[-200px] h-[300px] w-[300px] sm:h-[400px] sm:w-[400px] md:h-[520px] md:w-[520px] blur-[120px] sm:blur-[150px] md:blur-[170px]"></div>
                </div>
            </section>

            {/* Futuristic Sections */}
            {renderFuturisticSections()}

            <Footer />
        </div>
    );
};

export default MobileAppPentesting;
