import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import gsap from "gsap";
import Navbar from '../../sections/Navbar';
import Footer from '../../sections/Footer';

import highlight_img from "../../../images/highlight_image.png"

const WebAppPenTesting = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesHovered, setIsServicesHovered] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsServicesHovered(false);
      }
    }

    if (isServicesHovered) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isServicesHovered]);

  return (
    <div className="bg-dark text-white font-sans">

      <Navbar />

      {/* Hero Section */}
      <section className="hero-section h-screen flex items-center justify-center bg-[#0E0E0E] bg-cover bg-center">
        <div className="relative h-full w-full overflow-hidden flex items-center justify-center">
          <div className="light_beams absolute top-0 left-1/2 transform -translate-x-1/2 w-full h-full pointer-events-none z-10">
            <div className="beam_1"></div>
            <div className="beam_2"></div>
            <div className="beam_3"></div>
          </div>
          <div className="p-4 sm:p-6 md:mx-10 lg:mx-20 xl:mx-32 text-left flex flex-col gap-4 md:gap-6 z-20">
            <h1 className="hero-title text-center text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 leading-tight max-w-[90%] sm:max-w-[56rem] text-white">
              Web Application Penetration Testing
            </h1>
          </div>
          <div className="hero-bg bg-[#D1F60A] absolute top-[-350px] sm:bottom-[-200px] h-[300px] w-[300px] sm:h-[400px] sm:w-[400px] md:h-[520px] md:w-[520px] blur-[120px] sm:blur-[150px] md:blur-[170px]"></div>
        </div>
      </section>

      {/* Bento Grid Section */}
      <section className="py-36 bg-[#0E0E0E]">
        <div className="container mx-auto px-6">
          <h2 className="text-center text-3xl font-semibold mb-8 text-white">Why AppSecNinja?</h2>

          <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4">

            {/* Hero Block */}
            <div className="relative overflow-hidden bg-[#1D1D1D] rounded-lg p-6 flex flex-col justify-center h-48 sm:h-64 lg:row-span-2 lg:h-full border border-transparent hover:border-[#D1F60A] transition-all duration-300 ease-in-out transform hover:scale-105 shadow-md hover:shadow-lg ">
              <h3 className="text-xl font-bold text-center text-white">Protect Your Digital Assets</h3>
              <p className="text-center text-white mt-2">Our comprehensive platform safeguards your business against emerging threats.</p>
              <button className="mt-4 px-4 py-2 bg-[#D1F60A] text-black rounded-lg">Get Started</button>
            </div>

            {/* Why Choose Us */}
            <div className="relative overflow-hidden bg-[#1D1D1D] rounded-lg p-6 flex flex-col justify-center h-48 sm:h-64 border border-transparent hover:border-[#D1F60A] transition-all duration-300 ease-in-out transform hover:scale-105 shadow-md hover:shadow-lg ">
              <h3 className="text-xl font-bold text-left text-white">Why Choose Us</h3>
              <ul className="mt-2 text-white text-left">
                <li>Advanced Threat Detection</li>
                <li>Real-Time Monitoring</li>
                <li>ISO & GDPR Compliance</li>
                <li>User-Friendly Dashboard</li>
              </ul>
            </div>

            {/* Key Features */}
            <div className="relative overflow-hidden bg-[#1D1D1D] rounded-lg p-6 flex flex-col justify-center h-48 sm:h-64 border border-transparent hover:border-[#D1F60A] transition-all duration-300 ease-in-out transform hover:scale-105 shadow-md hover:shadow-lg ">
              <h3 className="text-xl font-bold text-left text-white">Powerful Features</h3>
              <ul className="mt-2 text-white text-left">
                <li>Firewall & VPN Protection</li>
                <li>Behavioral Analytics</li>
                <li>Automated Response</li>
                <li>AI-Powered Threat Analysis</li>
              </ul>
            </div>

            {/* Getting Started */}
            <div className="relative overflow-hidden bg-[#1D1D1D] rounded-lg p-6 flex flex-col justify-center h-48 sm:h-64 lg:col-span-2 sm:col-span-3 border border-transparent hover:border-[#D1F60A] transition-all duration-300 ease-in-out transform hover:scale-105 shadow-md hover:shadow-lg ">
              <h3 className="text-xl font-bold text-left text-white">Getting Started</h3>
              <ol className="mt-2 text-white text-left list-decimal list-inside">
                <li>Sign Up for Free - Create your account with just a few clicks.</li>
                <li>Complete Your Profile - Tailor the platform to your needs.</li>
                <li>Start Your First Assessment - Identify vulnerabilities and get recommendations.</li>
                <li>Engage with Our Support Team - Connect with our experts for guidance.</li>
              </ol>
            </div>

          </div>
        </div>
      </section>

      {/* Highlight Section */}
      <section className="w-full h-[300px] sm:h-[100px] md:h-[400px] lg:h-[450px] overflow-hidden relative">
        <img
          src={highlight_img}
          className="w-full h-full object-cover absolute top-0 left-0"
          alt="Highlight_Image"
        />
      </section>

      <Footer />

    </div>
  );
};

export default WebAppPenTesting;